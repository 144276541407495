import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
import { Link } from "gatsby";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h1" components={components}>{`Contact Us`}</MDXTag>
      <MDXTag name="p" components={components}><p>{`The `}<Link to="meet_us">{`meet us`}</Link>{` page has a number of ways to contact members directly, but you can contact us using the details here too.`}</p></MDXTag>
      <MDXTag name="p" components={components}>{`To talk to the team for any reason, you can:`}</MDXTag>
      <MDXTag name="p" components={components}>{`email: `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:team@dcdc.io"
        }}>{`team@dcdc.io`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`twitter: `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://twitter.com/dcdcio%22"
        }}>{`@dcdcio`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    